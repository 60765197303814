import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="rating"
export default class extends Controller {
  static values = {
    rating: Number,
    activeSrc: String,
    inactiveSrc: String,
  }

  static targets = [
    "review"
  ]

  connect() {
    this.#changeSrcForImages(this.ratingValue)
    this.#toggleReview(this.ratingValue)
  }

  setRating(event) {
    const selectedRating = event.currentTarget.dataset.rating;
    this.#changeSrcForImages(selectedRating)
    this.#toggleReview(selectedRating)
  }

  // Set label image to mini_app/star-inactive.png for labels with a lower value than the current target
  #changeSrcForImages(rating) {
    const labelImages = this.element.querySelectorAll("label img");
    labelImages.forEach((labelImage) => {
      labelImage.src =
        labelImage.dataset.rating <= rating ? this.activeSrcValue : this.inactiveSrcValue;
    })
  }

  // Toggle visibility block with attribute data-rating-target="review"
  #toggleReview(rating) {
    if (rating == 5) {
      this.reviewTarget.classList.add("hidden")
    } else {
      this.reviewTarget.classList.remove("hidden")
    }
  }
}
