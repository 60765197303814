import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="tab-filter"
export default class extends Controller {
  static values = {
    defaultKey: String,
    activeClass: String
  }

  static targets = [
    "option",
    "item"
  ]

  connect() {
    this.#toggleVisibilityForItems(this.defaultKeyValue)
    this.#toggleActiveClassForOptions(this.defaultKeyValue)
  }

  filterItems(event) {
    const selectedFilterKey = event.target.getAttribute("data-tab-filter-key")
    this.#toggleVisibilityForItems(selectedFilterKey)
    this.#toggleActiveClassForOptions(selectedFilterKey)
  }

  #toggleVisibilityForItems(value) {
    this.itemTargets.forEach((item) => {
      const filterableKey = item.getAttribute("data-tab-filter-key")
      item.classList.toggle("hidden", !filterableKey.includes(value))
    })
  }

  #toggleActiveClassForOptions(value) {
    this.optionTargets.forEach((option) => {
      const filterableKey = option.getAttribute("data-tab-filter-key")
      option.classList.toggle(this.activeClassValue, filterableKey.includes(value))
    })
  }
}
