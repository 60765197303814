import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="addresses"
export default class extends Controller {
  static targets = ["map", "branch"];

  static values = {
    branches: Array,
    placemark: String,
  };

  connect() {
    this.initMap();
  }

  async initMap() {
    console.log("initMap");

    await ymaps3.ready;
    const { YMap, YMapDefaultSchemeLayer, YMapDefaultFeaturesLayer } = ymaps3;

    const { YMapDefaultMarker } = await ymaps3.import(
      "@yandex/ymaps3-markers@0.0.1",
    );

    if (!this.hasMapTarget || !this.hasBranchesValue) {
      return;
    }

    const center = this.branchesValue[2].xy.reverse();

    this.ymap = new YMap(
      this.mapTarget,
      {
        location: {
          center: center,
          zoom: 11,
        },
      },
      [new YMapDefaultSchemeLayer({}), new YMapDefaultFeaturesLayer({})],
    );

    this.branchesValue.forEach((branch) => {
      const marker = new ymaps3.YMapMarker(
        {
          coordinates: branch.xy.reverse(),
        },
        this.#createPlacemarkContent(branch.xy)
      )
      this.ymap.addChild(marker);
    });
  }

  show(event) {
    this.#toggleBranchCardClasses(event.currentTarget)
    this.ymap.setLocation({
      center: event.params.coordinates,
      zoom: 15,
    });
  }

  #createPlacemarkContent(coordinates) {
    const parser = new DOMParser()
    const placemarkElement = parser.parseFromString(`
      <div class="-translate-x-7 -translate-y-14 cursor-pointer">
        <div class="size-14">
          <img src="${this.placemarkValue}">
        </div>
      </div>
    `, "text/html").body.childNodes[0]
    placemarkElement.addEventListener("click", this.#setBranchActive.bind(this, coordinates))
    return placemarkElement
  }

  #setBranchActive(coordinates) {
    const currentBranch = this.branchTargets.find((branch) => {
      return branch.dataset.addressesCoordinatesParam.includes(coordinates.join(', '))
    })
    this.#toggleBranchCardClasses(currentBranch)
    currentBranch.scrollIntoView({ behavior: "smooth", block: "start" });
  }

  #toggleBranchCardClasses(currentBranch) {
    this.branchTargets.forEach((branch) => {
      branch.classList.remove("border-primary");
      branch.classList.add("border-transparent");
    });
    currentBranch.classList.remove("border-transparent");
    currentBranch.classList.add("border-primary");
  }
}
