import { Controller } from "@hotwired/stimulus"

// Used to create a modal window using the <dialog> tag
// To start you need:
// 1. <button> tag in your HTML to open <dialog> with unique selector (variable name in code - "openModalButton");
// 2. <dialog> tag in your HTML (can be placed anywhere).

// Connects to data-controller="dialog"
export default class extends Controller {
  static values = {
    openModalButtonSelector: String
  }

  connect() {
    this.openModalButton = document.querySelector(this.openModalButtonSelectorValue)
    this.openModalButton.addEventListener('click', this.#openModal.bind(this))
    this.element.addEventListener('click', this.#closeOnBackdropClick.bind(this))
  }

  closeModal() {
    this.element.close()
  }

  #openModal() {
    this.element.showModal()
  }

  // If click occurred on <dialog> tag, close dialog
  #closeOnBackdropClick({ currentTarget, target }) {
    const dialogElement = currentTarget
    const isClickedOnBackdrop = target === dialogElement
    if (isClickedOnBackdrop) {
      dialogElement.close()
    }
  }
}
