import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

// Connects to data-controller="mini-app--back-button"
export default class extends Controller {
  static values = {
    isCustomer: String
  }

  connect() {
    this.pagesToClearHistory = ["/payment/new"]
    if (this.#hasCustomerChanged) this.#clearHistorySessionStorage()
    this.historyLength = Number(sessionStorage.getItem("history_length"))
    this.#toggleBackButton()
    this.#initCallbackLinks()
    this.#toggleEventListener(true)
  }

  disconnect() {
    this.#toggleEventListener(false)
  }

  get #hasCustomerChanged() {
    return sessionStorage.getItem("is_customer") !== this.isCustomerValue
  }

  #historyBack() {
    history.back()
    sessionStorage.setItem("history_length", this.historyLength - 1)
  }

  #incrementHistoryLength(event) {
    if (this.#isClearHistory(event.detail.url)) {
      this.#clearHistorySessionStorage()
      window.Telegram.WebApp.BackButton.hide()
    } else {
      sessionStorage.setItem("history_length", this.historyLength + 1)
    }
  }

  #isClearHistory(url) {
    return this.pagesToClearHistory.some(path => url.includes(path))
  }

  #toggleBackButton() {
    if (this.historyLength) {
      window.Telegram.WebApp.BackButton.show()
    } else {
      window.Telegram.WebApp.BackButton.hide()
    }
  }

  #toggleEventListener(isEnableListeners) {
    if (isEnableListeners) {
      window.Telegram.WebApp.BackButton.onClick(this.boundHistoryBack)
      addEventListener("turbo:before-visit", this.boundIncrementHistoryLength)
      addEventListener("popstate", this.boundSendRequestAfterHistoryBack)
    } else {
      window.Telegram.WebApp.BackButton.offClick(this.boundHistoryBack)
      removeEventListener("turbo:before-visit", this.boundIncrementHistoryLength)
      removeEventListener("popstate", this.boundSendRequestAfterHistoryBack)
    }
  }

  // https://betterstimulus.com/events/events
  #initCallbackLinks() {
    this.boundIncrementHistoryLength = this.#incrementHistoryLength.bind(this)
    this.boundHistoryBack = this.#historyBack.bind(this)
    this.boundSendRequestAfterHistoryBack = this.#sendRequestAfterHistoryBack.bind(this)
  }

  #clearHistorySessionStorage() {
    sessionStorage.setItem("is_customer", this.isCustomerValue)
    sessionStorage.setItem("history_length", 0)
  }

  #sendRequestAfterHistoryBack() {
    Turbo.visit(window.location.pathname)
    sessionStorage.setItem("history_length", this.historyLength - 1)
  }
}
