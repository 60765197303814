import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails";

// Connects to data-controller="mini-app--addresses"
export default class extends Controller {
  static targets = [
    "map",
    "branch",
  ];

  static values = {
    branches: Array,
    placemark: String,
  };

  connect() {
    this.#initMap()
  }

  async #initMap() {
    await ymaps3.ready
    this.ymap = new ymaps3.YMap(
      this.mapTarget,
      {
        location: {
          center: this.branchesValue[0]["xy"].reverse(),
          zoom: 11,
        },
      },
      [new ymaps3.YMapDefaultSchemeLayer({}), new ymaps3.YMapDefaultFeaturesLayer({})],
    )

    this.#setCenterForMap()
    this.branchesValue.forEach(this.#createPlacemarkForMap.bind(this));
  }

  #setCenterForMap() {
    this.ymap.setLocation(
      {
        bounds: [
          this.branchesValue[0]["xy"].reverse(),
          this.branchesValue[2]["xy"].reverse(),
        ],
      }
    );
    this.ymap.setLocation({
      zoom: 11
    });
  }

  #createPlacemarkForMap(branch) {
    const marker = new ymaps3.YMapMarker(
      {
        coordinates: branch.xy.reverse(),
      },
      this.#createPlacemarkContent(branch)
    )
    this.ymap.addChild(marker);
  }

  #createPlacemarkContent(branch) {
    const classForBranchLoad = {
      "low": "bg-mini-app-green-500",
      "medium": "bg-mini-app-orange-500",
      "high": "bg-mini-app-red-500"
    }[branch.load]

    const parser = new DOMParser()
    const placemarkElement = parser.parseFromString(`
      <div class="-translate-x-2/4 -translate-y-14 cursor-pointer">
        <img src="${this.placemarkValue}" class="mx-auto mb-0.5 size-14">
        <div class="flex w-max items-center gap-x-1 rounded-2xl bg-white p-1 pr-2">
          <div class="size-4 rounded-full ${classForBranchLoad}"></div>
          <div class="text-xxs font-semibold">${branch.site}</div>
        </div>
      </div>
    `, "text/html").body.childNodes[0]

    placemarkElement.addEventListener("click", () => {
      Turbo.visit(`/mini_app/branches/${branch.id}`)
    })

    return placemarkElement
  }
}
